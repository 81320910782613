import safeGet from 'just-safe-get'
import { useStore } from 'vuex'
import { ref, computed, onBeforeMount } from 'vue'

/* 
This mixin helps with filterable headers config save and load from store

Example:
<template>
  <th-datatable
    headers-filterable
    :headers-config="headersConfig"
    @headers-config="handleHeadersConfig"
  >
  </th-datatable>
</template>

<script>
import datatableHeadersConfig from '@/mixins/datatable-headers-config'

export default {
  setup() {
    const { headersConfig, handleHeadersConfig } = datatableHeadersConfig(
      'settings.headerFilters.<path>'
    )
    return {
      headersConfig,
      handleHeadersConfig
    }
  }
}
</script>
*/

export function datatableHeadersConfig(headersConfigPathValue = null) {
  const store = useStore()
  const headersConfigPath = ref(headersConfigPathValue)
  const headersConfig = computed(
    () =>
      safeGet(store.state.Config.localConfiguration, headersConfigPath.value) ||
      {}
  )

  function handleHeadersConfig(config) {
    store.dispatch('Config/setLocalConfigurationValue', {
      path: headersConfigPath.value,
      value: config || {}
    })
  }
  onBeforeMount(() => {
    if (headersConfigPath.value === null) {
      throw new Error("Variable 'headersConfigPath' is missing!")
    }
  })
  return {
    headersConfig,
    headersConfigPath,
    handleHeadersConfig
  }
}

export default datatableHeadersConfig
