<template>
  <th-page-wrapper>
    <th-datatable
      ref="tableRef"
      sortable
      do-route-filters
      resource="products"
      route-base="/inventory/stock"
      show-search-filter
      :buttons="buttons"
      :search-filters="filtersList"
      :show-operations="false"
      :resource-limit="100"
      :resource-query="resourceQuery"
      :locale="locale"
      :export-options="{
        waitingContent: $t('common.interactions.download.waiting')
      }"
      :headers="headers"
      :transform-table-data="
        (data) => data.map((row) => ({ ...row, children: null }))
      "
      @loading-error="handleLoadingError"
    />
  </th-page-wrapper>
</template>

<script setup>
import { ref, computed, watch, inject } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import qs from 'qs'
import th from '@tillhub/javascript-sdk'
import { useExportsStore } from '@/store/exports'

const EMPTY_CELL_TXT = '-'

const resourceFetch = inject('resourceFetch')
const logException = inject('logException')
const store = useStore()
const { t } = useI18n()

const route = useRoute()

const tableRef = ref(null)

const currentLocation = computed(
  () => store.getters['Config/getCurrentLocation']
)

const buttons = computed(() => [
  {
    type: 'custom_export',
    scopes: ['supplier_management:suppliers:export'],
    clickHandler: handleExport
  }
])

const filtersList = ref([
  {
    name: 'custom_id',
    type: 'remote-search-select',
    label: t('pages.products.all.filters.custom_id.label'),
    placeholder: t('common.inputs.placeholders.search'),
    resource: 'products',
    optionsValue: 'custom_id',
    computedFields: ['custom_id', 'name'],
    overrideInitialFetchHandler: 'get',
    fetchHandler: 'getAll',
    modifyQuery: (q) => ({
      q,
      limit: 50,
      deleted: false,
      exclude_system_products: true,
      type: [
        'product',
        'composed_product',
        'voucher',
        'linked',
        'linked_product',
        'variant',
        'variant_product'
      ],
      original_product: true
    })
  },
  {
    name: 'location',
    type: 'remote-search-select',
    label: t('common.headers.location.title'),
    resource: 'branches',
    filterable: true,
    doInitialFetch: true,
    computeName: (branch) => branch.name,
    modifyQuery: (q) => ({
      q,
      fields: ['name']
    }),
    appendToBody: true
  }
])

const resourceQuery = computed(() => ({
  extended: true,
  location_strict: true,
  ...(currentLocation.value ? { location: currentLocation.value } : {})
}))

const parsedQuery = computed(() => {
  const parsedQuery = (qs.parse(route.query) || {}).filter
  return parsedQuery || {}
})

const locale = computed(() => store.getters['Config/getLocale'])
const timezone = computed(() => store.getters['Config/getTimeZone'])

const headers = computed(() => [
  {
    field: 'name',
    label: t('pages.stock_movements.all.table.product_name'),
    fallback: EMPTY_CELL_TXT,
    minWidth: 160,
    truncate: true
  },
  {
    field: 'type',
    label: t('common.headers.type.title'),
    fallback: EMPTY_CELL_TXT,
    minWidth: 120,
    truncate: true,
    formatter: ({ type }) => t(`common.products.types.${type}.title`)
  },
  {
    field: 'custom_id',
    label: t('pages.stock_movements.all.table.product_number'),
    fallback: EMPTY_CELL_TXT,
    minWidth: 150,
    truncate: true
  },
  {
    field: 'attributes',
    label: t('pages.products.all.headers.attributes'),
    fallback: EMPTY_CELL_TXT,
    minWidth: 150,
    truncate: true,
    formatter: ({ options, attributes }) => {
      const SEPARATOR = ' | '
      if (options?.length > 0) {
        let attributes = []
        options.forEach((option) => {
          Object.keys(option).forEach((attribute) => {
            attributes = attributes.concat(option[attribute])
          })
        })
        return attributes.filter((attribute) => attribute).join(SEPARATOR)
      } else {
        return Object.keys(attributes ?? {}).length > 0
          ? Object.values(attributes).join(SEPARATOR)
          : EMPTY_CELL_TXT
      }
    }
  },
  {
    field: 'stock_info',
    label: t('pages.products.all.headers.stock_info'),
    fallback: EMPTY_CELL_TXT,
    minWidth: 150,
    truncate: true,
    formatter: ({ type, stock_info }) =>
      type === 'variant'
        ? stock_info?.summary?.total?.qty ?? EMPTY_CELL_TXT
        : stock_info?.all?.total?.qty ?? EMPTY_CELL_TXT
  },
  {
    field: 'open_delivery',
    label: t('pages.stock_movements.all.table.open_delivery'),
    fallback: EMPTY_CELL_TXT,
    minWidth: 150,
    truncate: true
  }
])

function handleLoadingError(err) {
  logException(err, {
    trackError: false,
    message: t('common.error.action.read.multiple', {
      resources: t('pages.stock_movements.title')
    })
  })
}

async function handleExport({ resourceOptions }) {
  const query = {
    ...resourceOptions,
    query: {
      ...resourceOptions.query,
      ...(resourceOptions?.query?.custom_id ? { opt_out: 'performance' } : {}),
      format: 'csv',
      filename_prefix: t('pages.stock_movements.title'),
      timezone: timezone.value || 'Europe/Berlin'
    }
  }
  try {
    const { products = [] } = await resourceFetch({
      resource: 'products',
      handler: () => th.products().getAll(query)
    })

    const exportId = products[0]?.correlationId
    if (!exportId) {
      throw new Error(`Response data or correlation ID is missing`)
    }

    useExportsStore().setNewExport({
      exportId,
      payload: {
        originKey: 'pages.products.title',
        date: new Date(),
        action: {
          entity: 'products',
          query
        }
      }
    })
  } catch (err) {
    logException(err, {
      message: t('notifications.exports.error.text', {
        entity: t('pages.products.title')
      })
    })
  }
}

watch(parsedQuery, () => tableRef.value?.refresh())
</script>
