<template>
  <th-page-wrapper>
    <div class="flex items-center pt-8 px-6">
      <div
        v-for="tab in tabs"
        :key="tab.key"
        class="item block cursor-pointer px-4 py-1 text-sm border-transparent"
        :class="{ active: activeTab === tab.key }"
        @click="setTab(tab.key)"
        v-text="tab.label"
      />
    </div>

    <component :is="tabComponents[activeTab]" />
  </th-page-wrapper>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import Products from './products'
import StockMovements from './stock-movements'
import ConsignmentNotes from './consignment-notes'

const route = useRoute()
const router = useRouter()
const { t } = useI18n()

const tabs = computed(() => [
  {
    key: 'products',
    label: t('pages.products.edit.sections.overview.title')
  },
  {
    key: 'stock-movements',
    label: t('nav.main.items.inventory.stock_movements')
  },
  {
    key: 'consignment-notes',
    label: t('pages.inventory.stocks.goods_in.delivery_notes')
  }
])

const tabComponents = computed(() => ({
  [tabs.value[0].key]: Products,
  [tabs.value[1].key]: StockMovements,
  [tabs.value[2].key]: ConsignmentNotes
}))

const activeTab = ref(null)

function setTab(tab) {
  if (activeTab.value === tab) return
  activeTab.value = tab
  router.replace({ query: { tab } })
}

onMounted(() => {
  activeTab.value = route.query.tab || tabs.value[0].key
})
</script>

<style scoped>
.item {
  border-bottom-width: 3px;
}

.item.active {
  font-weight: bold;
  color: var(--primary-color);
  border-color: var(--primary-color) !important;
  line-height: var(--el-line-height);
}
</style>
