<template>
  <th-page-wrapper>
    <th-datatable
      ref="tableRef"
      sortable
      do-route-filters
      no-meta-check
      resource="consignmentNotes"
      route-base="/inventory/stock"
      show-search-filter
      :search-filters="filtersList"
      :show-operations="false"
      :resource-limit="100"
      :resource-query="resourceQuery"
      :locale="locale"
      :export-options="{
        waitingContent: $t('common.interactions.download.waiting')
      }"
      :headers="headers"
      @loading-error="handleLoadingError"
    />
  </th-page-wrapper>
</template>

<script setup>
import { ref, computed, watch, inject } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import qs from 'qs'
import ConsignmentNoteTableRowDownload from './components/consignment-note-table-row-download.vue'
import { formatDateTime, formatDateRange } from '@/utils/date'

const EMPTY_CELL_TXT = '-'

const logException = inject('logException')
const store = useStore()
const { t } = useI18n()

const route = useRoute()

const tableRef = ref(null)

const currentLocation = computed(
  () => store.getters['Config/getCurrentLocation']
)

const filtersList = ref([
  {
    name: 'consignmentNoteNumber',
    type: 'input',
    label: 'Delivery Note Number' // TODO: add translation
  },
  {
    name: 'date',
    type: 'daterange',
    prop: ['start', 'end'],
    preventDefaultDates: true,
    label: t('pages.stock_movements.all.filters.daterange.label'),
    formatValue: (value) => formatDateRange(value),
    modifyFilter: ({ start, end }) => {
      // if end date is in the future, send now as the end date
      const now = new Date()
      const endDate = now < new Date(end) ? now.toISOString() : end
      return { start, end: endDate }
    },
    noFutureDates: true
  }
])

const resourceQuery = computed(() => ({
  ...(currentLocation.value ? { location: currentLocation.value } : {})
}))

const parsedQuery = computed(() => {
  const parsedQuery = (qs.parse(route.query) || {}).filter
  return parsedQuery || {}
})

const locale = computed(() => store.getters['Config/getLocale'])

const headers = computed(() => [
  {
    field: 'createdAt',
    label: t('pages.consignment_notes.headers.delivery_note_date'),
    minWidth: 160,
    truncate: true,
    formatter: (row) =>
      row.createdAt ? formatDateTime(row.createdAt) : EMPTY_CELL_TXT
  },
  {
    field: 'consignmentNoteNumber',
    label: t('pages.consignment_notes.headers.delivery_note_no'),
    fallback: EMPTY_CELL_TXT,
    minWidth: 120,
    truncate: true
  },
  {
    field: 'custom_id',
    label: t('pages.consignment_notes.headers.download_delivery_note'),
    customRowComponent: ConsignmentNoteTableRowDownload,
    minWidth: 150,
    truncate: true
  }
])

function handleLoadingError(err) {
  logException(err, {
    trackError: false,
    message: t('common.error.action.read.multiple', {
      resources: t('pages.stock_movements.title')
    })
  })
}

watch(parsedQuery, () => tableRef.value?.refresh())
</script>
