<template>
  <th-page-wrapper>
    <th-datatable
      ref="table"
      :do-route="false"
      do-route-filters
      show-search-filter
      resource="stocksBookV1"
      route-base="/inventory/stock"
      :show-operations="false"
      :resource-query="resourceQuery"
      force-meta-check
      :search-filters="filtersList"
      :buttons="computedButtons"
      :locale="locale"
      :export-options="{
        waitingContent: $t('common.interactions.download.waiting')
      }"
      headers-filterable
      :headers="headers"
      :headers-config="headersConfig"
      :transform-fetched-data="transformFetchedData"
      @headers-config="handleHeadersConfig"
      @loading-error="handleLoadingError"
    />
  </th-page-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import qs from 'qs'
import { mapGetters } from 'vuex'
import safeGet from 'just-safe-get'
import mapValues from 'just-map-values'
import compare from 'just-compare'
import datatableHeadersConfig from '@/mixins/datatable-headers-config'
import { applyFiltersBeforeRouteEnter, getRangeFor } from '@/utils/date'
import { useExportsStore } from '@/store/exports'

export default {
  metaInfo() {
    return {
      title: this.$t('nav.main.items.inventory.stock_movements')
    }
  },
  beforeRouteEnter: (to, from, next) => {
    // doing stuff here is very dangerous as it might lead to infinite route loops
    applyFiltersBeforeRouteEnter({ path: to.path, query: to.query, next })
  },
  setup() {
    const { headersConfig, handleHeadersConfig } = datatableHeadersConfig(
      'settings.headerFilters.inventory.stock_movements'
    )
    return {
      headersConfig,
      handleHeadersConfig
    }
  },
  data() {
    return {
      staff: '',
      product: undefined,
      filterOptions: {},
      resources: {},
      searchFilterValues: {},
      searchFilters: {},
      width: 500,
      headers: [
        {
          field: 'created_at',
          label: this.$t('pages.stock_movements.all.table.date'),
          fallback: '-',
          minWidth: 160,
          truncate: true,
          formatter: ({ created_at, timezone }) => {
            const date = created_at?.iso
            if (!date) return '--'
            return this.$date.formatDateTimeWithTimezone(date, timezone)
          }
        },
        {
          field: 'type',
          label: this.$t('common.headers.type.title'),
          fallback: '-',
          minWidth: 120,
          truncate: true,
          formatter: (row, column) => this.typeTranslations[row?.type] || '-'
        },
        {
          field: 'from',
          label: this.$t('pages.stock_movements.all.table.from'),
          fallback: '-',
          minWidth: 150,
          truncate: true,
          formatter: ({ from }) =>
            from?.name || from?.insert_id || from?.custom_id || '--'
        },
        {
          field: 'to',
          label: this.$t('pages.stock_movements.all.table.to'),
          fallback: '-',
          minWidth: 150,
          truncate: true,
          formatter: ({ to }) =>
            to?.name || to?.insert_id || to?.custom_id || '--'
        },
        {
          field: 'qty_change',
          label: this.$t('pages.stock_movements.all.table.quantity_change'),
          fallback: '-',
          minWidth: 160,
          align: 'right',
          truncate: true,
          formatter: ({ qty_change }) => {
            const num = parseFloat(qty_change)
            return (isNaN(num) ? 0 : num).toLocaleString(this.locale)
          }
        },
        {
          field: 'location',
          label: this.$t('common.headers.location.title'),
          fallback: '-',
          minWidth: 150,
          truncate: true,
          formatter: ({ location }) =>
            location?.name || location?.insert_id || location?.custom_id || '--'
        },
        {
          field: 'qty',
          label: this.$t('pages.stock_movements.all.table.current_quantity'),
          fallback: '-',
          minWidth: 140,
          align: 'right',
          truncate: true,
          formatter: ({ qty }) => {
            return (qty || 0).toLocaleString(this.locale)
          }
        },
        {
          field: 'product_custom_id',
          label: this.$t('pages.stock_movements.all.table.product_number'),
          fallback: '-',
          minWidth: 120,
          align: 'right',
          truncate: true,
          formatter: ({ product }) => product?.custom_id || '--'
        },
        {
          field: 'product_name',
          label: this.$t('pages.stock_movements.all.table.product_name'),
          fallback: '-',
          minWidth: 160,
          truncate: true,
          formatter: ({ product }) => product?.name || '--'
        },
        {
          field: 'product_group_name',
          label: this.$t('pages.stock_movements.all.table.product_group'),
          fallback: '-',
          minWidth: 140,
          truncate: true,
          formatter: ({ product_group }) =>
            product_group?.name || product_group?.product_group_id || '--'
        },
        {
          field: 'reason',
          label: this.$t('pages.stock_movements.all.table.reason'),
          fallback: '-',
          minWidth: 120,
          align: 'right',
          truncate: true,
          formatter: ({ reason }) =>
            this.resources.reasons?.find(({ id }) => id === reason)?.name ||
            reason ||
            '--'
        }
      ],
      headersDefaultHide: ['reason'],
      buttons: [
        {
          type: 'custom_export',
          scopes: ['inventory:stock_movements:export'],
          label: this.$t('common.interactions.buttons.export'),
          clickHandler: ({ resourceOptions }) =>
            this.handleExport({ resourceOptions })
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      currentLocation: 'Config/getCurrentLocation',
      locale: 'Config/getLocale',
      timeZone: 'Config/getTimeZone',
      defaultDateSelected: 'Config/getDefaultDateSelected'
    }),
    filtersList() {
      return [
        {
          name: 'product',
          type: 'remote-search-select',
          placeholder: this.$t('common.inputs.placeholders.search'),
          label: this.$t(
            'pages.reports.statistics.sold_cart_items.filters.product'
          ),
          computedFields: ['custom_id', 'name'],
          resource: 'products',
          modifyQuery: (q) => ({
            q,
            limit: 50,
            deleted: false,
            original_product: true
          })
        },
        {
          name: 'product_group',
          type: 'remote-search-select',
          placeholder: this.$t('common.inputs.placeholders.search'),
          label: this.$t('pages.products.all.filters.product_group.label'),
          computedFields: ['product_group_id', 'name'],
          resource: 'productGroups',
          modifyQuery: (q) => ({ q, limit: 50, deleted: false })
        },
        {
          name: 'type',
          type: 'select',
          placeholder: this.$t('common.inputs.placeholders.select'),
          label: this.$t('common.headers.type.title'),
          filterable: true,
          options: Object.entries(
            this.typeTranslations
          ).map(([value, label]) => ({ value, label }))
        },
        {
          name: 'reason',
          type: 'remote-search-select',
          placeholder: this.$t(
            'pages.stock_movements.all.filters.reason.placeholder'
          ),
          label: this.$t('pages.stock_movements.all.table.reason'),
          filterable: true,
          resource: 'reasons',
          computedFields: ['name'],
          modifyQuery: (q) => ({ q, limit: 50, deleted: false })
        },
        {
          name: 'date',
          type: 'daterange',
          prop: ['start', 'end'],
          label: this.$t('pages.stock_movements.all.filters.daterange.label'),
          formatValue: (value) => this.$date.formatDateRange(value),
          modifyFilter: ({ start, end }) => ({ start, end }),
          noFutureDates: true,
          closable: false,
          default: getRangeFor[this.defaultDateSelected]?.()
        }
      ]
    },
    parsedQuery() {
      const parsedQuery = (qs.parse(this.$route.query) || {}).filter

      return parsedQuery || {}
    },
    resourceQuery() {
      return {
        deleted: false,
        embed: ['product', 'location', 'product_group'],
        location: this.location
      }
    },
    location() {
      return this.currentLocation || this.parsedQuery.location || undefined
    },
    hasFilters() {
      return !!this.parsedFilter
    },
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    },
    typeTranslations() {
      return {
        goods_in: this.$t('nav.main.items.inventory.goods_in'),
        goods_out: this.$t('nav.main.items.inventory.goods_out'),
        relocation: this.$t('nav.main.items.inventory.relocation'),
        sale: this.$t('common.transactions.types.sale'),
        cancellation: this.$t('common.transactions.types.sale_cancel'),
        refund: this.$t('common.transactions.types.refund'),
        delivery_note: this.$t('common.transactions.types.delivery_note'),
        delivery_note_refund: this.$t(
          'common.transactions.types.delivery_note_refund'
        ),
        delivery_note_cancellation: this.$t(
          'common.transactions.types.delivery_note_cancellation'
        )
      }
    }
  },
  watch: {
    'parsedQuery.product': {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          this.fetchProduct(newValue)
        }
      }
    },
    parsedQuery(newValue, oldValue) {
      if (!compare(newValue, oldValue)) {
        this.$refs.table.refresh()
      }
    }
  },
  mounted() {
    this.$emitter.on('refresh-requested', () => {
      this.$refs.table.refresh()
    })
  },
  beforeUnmount() {
    this.$emitter.off('refresh-requested')
  },
  async created() {
    this.fetchResources()
  },
  methods: {
    async fetchResources() {
      try {
        this.resources = await this.$resourceFetch('reasons')
      } catch (err) {
        this.$logException(err, { trackError: false })
      }
    },
    transformFetchedData(data) {
      return safeGet(data[0], 'values')
    },
    makeQuery(v) {
      const q = { ...this.parsedQuery, ...v }
      const filter = mapValues(q, (value) => value ?? undefined)

      const route = this.$makeFilteredPath(this.$route.path, filter)
      this.$safePush(route)
    },
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.stock_movements.title')
        })
      })
    },
    async handleExport({ resourceOptions }) {
      const query = {
        ...resourceOptions,
        query: {
          ...resourceOptions.query,
          format: 'csv',
          filename_prefix: this.$t('pages.stock_movements.title'),
          timezone: this.timeZone || 'Europe/Berlin'
        }
      }

      try {
        const { data } = await th.stocksBookV1().getAll(query)

        const exportId = data?.[0]?.correlationId
        if (!exportId) {
          throw new Error(`Response data or correlation ID is missing`)
        }

        useExportsStore().setNewExport({
          exportId,
          payload: {
            originKey: 'pages.stock_movements.title',
            date: new Date(),
            action: {
              entity: 'stocksBookV1',
              handler: 'getAll',
              query
            }
          }
        })
      } catch (err) {
        this.$logException(err, {
          message: this.$t('notifications.exports.error.text', {
            entity: this.$t('pages.stock_movements.title')
          })
        })
      }
    },
    async fetchProduct(product) {
      try {
        const { data } = await th.products().get(product)
        this.product = data
      } catch (error) {
        this.$logException(error, { trackError: false })
      }
    }
  }
}
</script>
