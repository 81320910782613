<template>
  <el-button v-loading="isLoading" @click.stop="handleClick">
    {{ $t('common.interactions.buttons.download') }}
  </el-button>
</template>

<script setup>
import th from '@tillhub/javascript-sdk'
import { defineProps, inject, ref } from 'vue'

const props = defineProps({
  scope: {
    type: Object,
    default: () => ({})
  }
})

const logException = inject('logException')

const isLoading = ref(false)

async function handleClick() {
  try {
    const consignmentNoteId = props.scope.row.id
    isLoading.value = true
    const { data, contentType, filename } = await th
      .consignmentNotes()
      .pdfUri(consignmentNoteId)
    const link = document.createElement('a')
    link.href = `data:${contentType};base64,${data}`
    link.download = filename
    link.click()
  } catch (err) {
    logException(err, { trackError: false })
  } finally {
    isLoading.value = false
  }
}
</script>
